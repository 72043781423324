// import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
// import axios from 'axios';
// import React, { useEffect, useState } from 'react'
// import { useLocation } from 'react-router-dom';

// const MeetingList = () => {
//     const auth = JSON.parse(localStorage.getItem("userData"));
//     const [eventTypes, setEventTypes] = useState([]);
//       const [loading, setLoading] = useState(true);
//       const [error, setError] = useState(null);

//       const location = useLocation();
//       const { from, key,_id } = location.state || {};
//       console.log(_id)
//       useEffect(() => {
//         const fetchEventTypes = async () => {
//           try {
//             const response = await axios.get(process.env.REACT_APP_BASE_URL +
//             `api/calendar/calendar-events?userId=${auth?._id}`);
//             // Filter events where event.createdBy === auth?._id
//       const filteredEvents = response.data.data.dbMeetings.filter(event =>
//         event.createdBy._id === auth?._id
//       );
//             setEventTypes(filteredEvents);
            
//           } catch (err) {
//             setError("Failed to fetch event types.");
//             console.error(err);
//           } finally {
//             setLoading(false);
//           }
//         };
    
//         fetchEventTypes();
//       }, []);

//       const calculateDuration = (startTime, endTime) => {
//         // Convert startTime and endTime to Date objects
//         const start = new Date(startTime);
//         const end = new Date(endTime);
      
//         // Calculate the difference in milliseconds
//         const durationInMilliseconds = end - start;
      
//         // Convert milliseconds to minutes
//         const durationInMinutes = Math.floor(durationInMilliseconds / 60000); // 60,000 ms = 1 minute
      
//         return durationInMinutes;
//       };

//   return (
//    <>
//     <Box className="container">
//         <Grid container spacing={3}>
//           {eventTypes.map((event, i) => (
//             <Grid item xs={12} sm={6} md={4} key={i}>
//               <Card className="event-card" sx={{
//                 marginTop:"10px",
//                 marginBottom:"10px"
//               }}>
//                 <CardContent>
//                   {/* Event Title */}
//                   <Typography variant="h6" component="div" gutterBottom>
//                     {event.title}
//                   </Typography>

//                   {/* Event Description */}
//                   <Typography variant="body2" color="textSecondary" paragraph>
//                     {event.description}
//                   </Typography>

//                    {/* Event Requested From */}
//                    <Typography variant="h6" component="div" gutterBottom>
//                     Requested By {event.attendees[0].name}
//                   </Typography>

//                   {/* Event Duration */}
//                   <Typography variant="body2" color="textSecondary">
//                     Duration:{" "}
//                     {calculateDuration(event.startTime, event.endTime)} minutes
//                   </Typography>

//                   {/* Conditional Rendering Based on Status */}
//                   {event.status === "Pending" && (
//                     <Typography variant="body2" color="error" paragraph>
//                       Please wait for teacher approval.
//                     </Typography>
//                   )}

//                   {event.status === "Scheduled" && (
//                     <Box>
//                       <Typography variant="body2" color="primary" paragraph>
//                         Event is Scheduled.
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         color="textSecondary"
//                         paragraph
//                       >
//                         Start Time:{" "}
//                         {new Date(event.startTime).toLocaleDateString()} at{" "}
//                         {new Date(event.startTime).toLocaleTimeString([], {
//                           hour: "2-digit",
//                           minute: "2-digit",
//                           hour12: true,
//                         })}
//                       </Typography>
//                       {new Date() > new Date(event.endTime) ? (
//                         <Typography variant="body2" color="error" paragraph>
//                           Meeting link expired.
//                         </Typography>
//                       ) : (
//                         <Box display="flex" justifyContent="center" mt={2}>
//                           <button
//                             className="register-btn"
//                             onClick={() =>
//                               window.open(event.googleMeetLink, "_blank")
//                             }
//                           >
//                             Join Zoom Meeting
//                           </button>
//                         </Box>
//                       )}
//                     </Box>
//                   )}

//                   {event.status === "Cancelled" && (
//                     <Typography variant="body2" color="error" paragraph>
//                       Teacher rejected your meeting request.
//                     </Typography>
//                   )}

//                   {event.status === "Completed" && (
//                     <Typography variant="body2" color="textSecondary" paragraph>
//                       The meeting has been completed.
//                     </Typography>
//                   )}
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//    </>
//   )
// }

// export default MeetingList


import { Box, Card, CardContent, Grid, Typography, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MeetingList = () => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    const [eventTypes, setEventTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const location = useLocation();
    const { from, key, _id } = location.state || {};
    console.log(_id);

    const [loadingActions, setLoadingActions] = useState({});
    const [cancelLoading, setCancelLoading] = useState({});


    useEffect(() => {
        const fetchEventTypes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/calendar/calendar-events?userId=${auth?._id}`);
                const filteredEvents = response.data.data.dbMeetings.filter(event => event.createdBy._id === auth?._id);
                setEventTypes(filteredEvents);
            } catch (err) {
                setError("Failed to fetch event types.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchEventTypes();
    }, []);

    const calculateDuration = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        return Math.floor((end - start) / 60000);
    };

    // const handleAction = async (eventId, action) => {
    //     try {
    //         const endpoint = action === "accept"
    //             ? `${process.env.REACT_APP_BASE_URL}api/calendar/approve/${eventId}`
    //             : `${process.env.REACT_APP_BASE_URL}api/calendar/reject/${eventId}`;
            
    //         const response = await axios.post(endpoint);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log(response.data.message || "Action successful");
    //             enqueueSnackbar(response.data.message, { variant: 'success' });
    //             window.location.reload();
    //         } else {
    //             alert("Something went wrong");
    //         }
    //     } catch (error) {
    //         console.error("Action error:", error);
    //         alert("Error processing request");
    //     }
    // };


    const handleAction = async (eventId, action) => {
      // Set loader for this event and action
      setLoadingActions(prev => ({
        ...prev,
        [eventId]: { ...(prev[eventId] || {}), [action]: true },
      }));
    
      try {
        const endpoint = action === "accept"
          ? `${process.env.REACT_APP_BASE_URL}api/calendar/approve/${eventId}`
          : `${process.env.REACT_APP_BASE_URL}api/calendar/reject/${eventId}`;
    
        const response = await axios.post(endpoint);
        if (response.status === 200 || response.status === 201) {
          console.log(response.data.message || "Action successful");
          enqueueSnackbar(response.data.message, { variant: 'success' });
          window.location.reload(); // Optional: ideally use state instead
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        console.error("Action error:", error);
        alert("Error processing request");
      } finally {
        // Clear loader
        setLoadingActions(prev => ({
          ...prev,
          [eventId]: { ...(prev[eventId] || {}), [action]: false },
        }));
      }
    };

    
  //   const handleScheduleMeeting = async (eventId) => {
  //     try {
  //          const endpoint = `${process.env.REACT_APP_BASE_URL}api/calendar/rejectFromGoogleCal/${eventId}`
          
  //         const response = await axios.post(endpoint);
  //         if (response.status === 200 || response.status === 201) {
  //             console.log(response.data.message || "Action successful");
  //             enqueueSnackbar(response.data.message, { variant: 'success' });
  //             window.location.reload();
  //         } else {
  //             alert("Something went wrong");
  //         }
  //     } catch (error) {
  //         console.error("Action error:", error);
  //         alert("Error processing request");
  //     }
  // };

  const handleScheduleMeeting = async (eventId) => {
    // Set loader true for this specific event
    setCancelLoading(prev => ({ ...prev, [eventId]: true }));
  
    try {
      const endpoint = `${process.env.REACT_APP_BASE_URL}api/calendar/rejectFromGoogleCal/${eventId}`;
      const response = await axios.post(endpoint);
  
      if (response.status === 200 || response.status === 201) {
        console.log(response.data.message || "Action successful");
        enqueueSnackbar(response.data.message, { variant: 'success' });
        window.location.reload();
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.error("Action error:", error);
      alert("Error processing request");
    } finally {
      // Turn off the loader
      setCancelLoading(prev => ({ ...prev, [eventId]: false }));
    }
  };

    return (
      <Box className="container">
        <Grid container spacing={3}>
          {eventTypes.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="textSecondary">
                No scheduled events request available at the moment.
              </Typography>
            </Grid>
          ):
          (eventTypes.map((event, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Card
                className="event-card"
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {event.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    {event.description}
                  </Typography>
                  <Typography variant="h6" component="div" gutterBottom>
                    Requested By {event.attendees[0].name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Duration:{" "}
                    {calculateDuration(event.startTime, event.endTime)} minutes
                  </Typography>

                  {event.status === "Pending" && (
                    <Box className="mt-10">
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAction(event._id, "accept")}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleAction(event._id, "reject")}
                        sx={{ marginLeft: "10px" }}
                      >
                        Reject
                      </Button> */}

                      <Button
                        variant="contained"
                        onClick={() => handleAction(event._id, "accept")}
                        disabled={loadingActions[event._id]?.accept}
                        sx={{
                          backgroundColor: "#4caf50",
                          color: "#fff",
                          '&:hover': {
                            backgroundColor: "#388e3c",
                          },
                        }}
                      >
                        {loadingActions[event._id]?.accept ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Accept"
                        )}
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => handleAction(event._id, "reject")}
                        disabled={loadingActions[event._id]?.reject}
                        sx={{
                          backgroundColor: "#f44336",
                          color: "#fff",
                          marginLeft: "10px",
                          '&:hover': {
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        {loadingActions[event._id]?.reject ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Reject"
                        )}
                      </Button>

                    </Box>
                  )}

                  {/* {event.status === "Scheduled" && (
                    <Box>
                      <Typography variant="body2" color="primary" paragraph>
                        Event is Scheduled.
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        paragraph
                      >
                        Start Time:{" "}
                        {new Date(event.startTime).toLocaleDateString()} at{" "}
                        {new Date(event.startTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </Typography>
                      {new Date() > new Date(event.endTime) ? (
                        <Typography variant="body2" color="error" paragraph>
                          Meeting link expired.
                        </Typography>
                      ) : (
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              window.open(event.googleMeetLink, "_blank")
                            }
                          >
                            Join Zoom Meeting
                          </Button>
                        </Box>
                      )}
                      <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleAction(event._id, "reject")}
                        >
                          Cancel Meeting
                        </Button>
                      </Box>
                    </Box>
                  )} */}

                  {event.status === "Scheduled" && (
                    <Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        paragraph
                      >
                        Start Time:{" "}
                        {new Date(event.startTime).toLocaleDateString()} at{" "}
                        {new Date(event.startTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </Typography>
                      {new Date() > new Date(event.endTime) ? (
                        <Typography variant="body2" color="error" paragraph>
                          Meeting link expired.
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="body2" color="primary" paragraph>
                            Event is Scheduled.
                          </Typography>
                          <Box display="flex" justifyContent="center" mt={2}>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleScheduleMeeting(event._id)}
                                disabled={cancelLoading[event._id]}
                              >
                                {cancelLoading[event._id] ? (
                                  <CircularProgress size={20} color="inherit" />
                                ) : (
                                  "Cancel Meeting"
                                )}
                              </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  )}

                  {event.status === "Cancelled" && (
                    <Typography variant="body2" color="error" paragraph>
                      Meeting request rejected .
                    </Typography>
                  )}

                  {event.status === "Completed" && (
                    <Typography variant="body2" color="textSecondary" paragraph>
                      The meeting has been completed.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )))}
        </Grid>
      </Box>
    );
}

export default MeetingList;
