import * as React from 'react';
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Button, Tooltip, ClickAwayListener, Radio, RadioGroup, OutlinedInput, Checkbox, styled, ListItemText, FormControl, Grid, Select, FormGroup, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import axios from 'axios';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import { useCommunity } from "../Context/CommunityContext";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },


    },
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});


export default function DataTable({ params }) {
    const [communityData, setCommunityData] = React.useState(null)
    
    React.useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);
	
    const headerlocal = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const navigate = useNavigate()
    let location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const [buttondisable, setButtondisable] = React.useState(false)
    const [newpodcast, setNewPodcast] = React.useState({ title: "", description: "" });
    const [podcast, setPodcast] = React.useState([])
    const [podcastData, setPodcastData] = React.useState([])
    const [selecttedPodcast, setSelecttedPodcast] = React.useState([])
    const { communityPodcast, addCommunityPodcast, fetchCommunity } = useCommunity();
  
    const [audios, setAudio] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [selectedVideo, setSelectedVideo] = React.useState([]);
    const [audioSize, setAudioSize] = React.useState('')
    const [user, setUser] = React.useState([])
    const [community, setCommunity] = React.useState([])
    const [imageError, setImageError] = React.useState('')
    const [addPodcastFrom, setaddPodcastFrom] = React.useState('Community')
    const [videoPreviewUrl, setVideoPreviewUrl] = React.useState([]);
    const [isToggled, setToggled] = React.useState(false)
    const [selectGallert, setSelectGallery] = React.useState('Podcast')
    const [error, setError] = React.useState({ title: "", description: "", audio: "", image: "", video: "" });

    // React.useEffect(() => {
    //     getPodcast()
    // }, [])

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 40,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(20px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18.5,
            height: 18.5,
        },
        '& .MuiSwitch-track': {
            borderRadius: 18.5 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    
    
    

    const Validator = () => {
        if (!newpodcast.title && statesingle?.length == 0) {
            setError({ title: "This field is required.", audio: 'This field is required.' })
            setImageError("This field is required.")
            return false
        }
        if (!newpodcast.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (selectGallert === 'Podcast') {
            if (audios.length === 0) {
                setError({ audio: "Audio upload is required." })
                return false
            }
        } else if (selectGallert === 'Video') {
            if (selectedVideo.length === 0) {
                setError({ video: "Video upload is required." })
                return false
            }
        }
        return true
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    React.useEffect(() => {
        getCommunity()
    }, [])
    const[Tableloading,setTableloading]=React.useState(true)

    const getCommunity = async () => {

        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerlocal });
            setCommunity(response.data.community);
          
        } catch (error) {
            console.log(error.response, "error");
        }
       
        

    }

    const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
    const [coummunityName, setCommunityName] = React.useState([])
    const handlechangeCommunity = (event, item) => {
        const {
            target: { value },
        } = event;
        setCommunityName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunityval();
    }

    React.useEffect(() => {
        if (params.id && community.length > 0) {

            const currentCommunity = community.find(c => c._id === params.id);
            if (currentCommunity) {

                setCommunityName([currentCommunity.coummunity_name]);
            }
        }
    }, [params.id, community]);

    

    React.useEffect(() => {
        getCommunityval()
    }, [coummunityName])

    function getCommunityval() {
        var array = []
        const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
        selectedCommunity.map((item, i) => {
            var val = { coummunity_name: item.coummunity_name, coummunity_title: item.coummunity_title, category_id: item.category_id, _id: item._id, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedCommunity(array)
    }

    React.useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getUserdata`, { headers: headerlocal });
            setUser(response.data.getuserdata);
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [userName, setUserName] = React.useState([])
    const handleSelectUser = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setUserName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getArrayval()
    }
    React.useEffect(() => {
        getArrayval()
    }, [userName])
    function getArrayval() {
        var array = []
        const selectedUser = user.filter(content => userName.includes(content.username));
        
        selectedUser.map((item, i) => {
            var val = { username: item.username, user_id: item._id, email: item.email, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedUsers(array)
    }

    const handlePodcast = (e) => {
        const { name, value } = e.target;
        setNewPodcast({ ...newpodcast, [name]: value });
        setError('')
        setImageError('')
    };

    React.useEffect(() => {
       
    }, [audioSize]);


    const [audioPreview, setAudioPreview] = React.useState(null)
    const handleAudio = (e) => {
        const file = e.target.files[0];
        setAudio(file)
        setAudioPreview(URL.createObjectURL(file))
        if (!file) {
            return;
        }
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        const roundedFileSizeInMB = fileSizeInMB.toFixed(1);
        setAudioSize(String(roundedFileSizeInMB));

    }

    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Podcast' ? 'Video' : 'Podcast')
    }

    // const handleVideoChange = (e, index) => {
    //     setError(prevError => ({ ...prevError, video: '' }))
    //     var fileObj = [];
    //     var fileArray = [];
    //     var filepath = [];
    //     fileObj.push(e.target.files)
    //     for (let i = 0; i <= fileObj[0].length; i++) {
    //         if (i < fileObj[0].length) {
    //             fileArray.push(URL.createObjectURL(fileObj[0][i]))
    //             filepath.push(fileObj[0][i])
    //         }
    //     }
    //     setVideoPreviewUrl(fileArray)
    //     setSelectedVideo(filepath)
    // }

    const handleVideoChange = (e, index) => {
        // setImageError('')
        setError({ video: '' });
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].size > 25 * 1024 * 1024) {
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }
            fileArray.push(URL.createObjectURL(fileObj[0][i]));
            filepath.push(fileObj[0][i]);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    }

    const clearVideo = () => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };





    

    React.useEffect(() => {
        fetchCommunity(params.id);
    }, [params.id]);

    React.useEffect(() => {
        if(communityData){

            getPodcast()
        }
    }, [communityData]);
   


    const getPodcast = async () => {
        setTableloading(true)
        try {
            await axios.get(process.env.REACT_APP_BASE_URL +`podcast/getpodcastdropdown`, { headers: headerlocal }).then((res) => {
console.log(res.data,"dataasasa");

                res.data.getpodcast && res.data.getpodcast.forEach((item, i) => {
                    const isSelected =communityData && communityData.podcasts ? communityData.podcasts.some(podcast => podcast.podcast_id === item._id):false;
                    if (isSelected) {
                        setSelecttedPodcast(prevState => [...prevState, { name: item.name, podcast_id: item._id, description: item.description, thumbnail: item.thumbnail, audio: item.audio, audio_size: item.audio_size,podcastSlug:item.podcastSlug }]);
                    }
                    var value = { selectPodcast: isSelected };
                    Object.assign(item, value);
                })
                setPodcast(res.data.getpodcast)
            }).catch((err) => console.log(err, "error"))
        } catch (error) {
            console.log(error.response, "error");
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1500)
        }
    }


    const habdleSelectstaff = async (e, item, i) => {
        
        var podcasts = [...podcast]
        var value = item.selectPodcast
        if (value === false) {
            podcasts[i].selectPodcast = true;
            setPodcast(podcasts)
            setSelecttedPodcast(prev => prev.concat({ name: item.name, podcast_id: item._id, description: item.description, thumbnail: item.thumbnail, audio: item.audio, audio_size: item.audio_size,podcastSlug:item.podcastSlug}))
        }
        if (value === true) {
            var data1 = selecttedPodcast.filter((val) => val.podcast_id !== item._id)
            setSelecttedPodcast(data1)
            podcasts[i].selectPodcast = false;
            setPodcastData(podcasts)
        }
       
    }
    const handleUpdateCommunity = async () => {
        //     var valid = validate();
        // if (valid == true)
        //  {
        //         setTruebutton(true);
        const filteredData = [];
        const seenPodcasts = {};

        selecttedPodcast.forEach(item => {
            if (!seenPodcasts[item.podcast_id]) {
                filteredData.push(item);
                seenPodcasts[item.podcast_id] = true;
            }
        })

        const formData = new FormData();
        formData.append('podcasts', JSON.stringify(filteredData.length > 0 ? filteredData : []))
       console.log([...formData])
       
        axios.post(process.env.REACT_APP_BASE_URL + `community/UpdatePodcastCommunity/${communityData?._id}`, formData, { headers: headerlocal }).then((res) => {
            // setTruebutton(false);
            setSelecttedPodcast([]);
            fetchCommunity(params.id)
          
            // navigate('/community')
            enqueueSnackbar("Community updated successfully", { variant: 'success' });
            localStorage.setItem('communityData', JSON.stringify(res.data.updatePodcastcommunity));

			// navigate(`/teachers/details/community/${params.id}`)

        }).catch((err) => {
            // setTruebutton(false);
            enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
            console.log(err, "error");
        });
        // }
    }
    const [truebutton,setTruebutton]=React.useState(false)
   

    
    const handleSubmit = async () => {
        const isValid = Validator();
        if (isValid) {
            setTruebutton(true)
            const formData = new FormData();
            console.log(communityData);
            
            
            formData.append('addPodcastFrom', addPodcastFrom)
            formData.append('createdby[]', communityData?._id);
            formData.append('name', newpodcast.title);
            // formData.append('audio', audios);
            formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            formData.append('community_id', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
            console.log(params,"dsfgwdfuv");
            
            // formData.append("createdbyTeacher[]", params?.id)
            // formData.append('audio_size', audioSize);
            formData.append('public', isToggled);
            formData.append('gallery', selectGallert === 'Podcast' ? 'Podcast' : 'Video')

            statesingle2.forEach((item) => formData.append("thumbnail", item));
            {
                selectGallert === 'Podcast' ? <>
                    {formData.append('audio', audios)}
                    {formData.append('audio_size', audioSize)}
                </>
                    :
                    <>
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
                    </>
            }
       console.log(...formData);
       
            axios.post(process.env.REACT_APP_BASE_URL + 'podcast/addPodcast', formData, { headers: headerlocal }).then((res) => {
                
                const resp = res.data.audio;
                if (resp) {
                    const obj = {
                        "name": resp.title || "",
                        "podcast_id": resp._id || "",
                        "audio": resp.audios || "",
                        "audio_size": resp.audioSize || ""
                    };
                 
                    addCommunityPodcast(obj);
                    fetchCommunity();
                    setNewPodcast({ title: '' })
                    setAudio('')
                    setStatesingle('')
                    setSelectedVideo('')
                }
                //navigate('/podcast')

                enqueueSnackbar("Podcast added successfully", { variant: 'success' });
                getPodcast();
            }).catch((err) => {
                enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : err.response.data.error ? err.response.data.error : "Something went wrong", { variant: 'error' });
                console.log(err);
            })
            .finally(()=>{
                setTruebutton(false)
            })
        }
    };


    
    return (
        <Box sx={{ width: '100%' }} className=" ">
            <Box className="whiteContainer mb-20">
                <Grid container className='newmerchant  mb-20'>
                    <Typography component='h6' className='formTitle'>Select Podcast</Typography>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list" >
                            <Grid container columnSpacing={2} rowSpacing={2}>

                                {podcast && podcast.length > 0 ? podcast.map((item, index) => (
                                    <Grid item sm={12} md={12} lg={6} xl={4} className="w-100 d-flex">

                                        <Box onClick={(e) => habdleSelectstaff(e, item, index)} className={item.selectPodcast === true ? "selected_staff staff_card" : "staff_card"} >
                                            <Check className='checksvg' />
                                            <Avatar className='mr-10' src={process.env.REACT_APP_BASE_URL + item.thumbnail} >{item && item.name && item.name.split("", 1)}</Avatar>
                                            <Box className="w100">
                                                <Box className='fz-16 semibold'> {item.name && item.name}</Box>
                                                <Box className='audioPlayer_cont'>
                                                    <AudioPlayer
                                                        src={process.env.REACT_APP_BASE_URL + (item.audio)}
                                                        // onPlay={e => console.log("onPlay")}
                                                        className='audio_player_css'
                                                    />
                                                </Box>

                                            </Box>

                                        </Box>
                                    </Grid>
                                )) : ""}
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
                <Box className='text-right p-20 pb-0'>
                    {buttondisable === false ? <Button variant="contained" onClick={handleUpdateCommunity} 
                        className='theme-btn'>Submit</Button> : <Button variant="contained" className='theme-btn'>Please wait</Button>}
                </Box>
            </Box>
            <Box className='whiteContainer content p-20'>
                <Typography component='h6' className='formTitle mb-20'>Add Podcast</Typography>
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl variant="standard" fullWidth>
                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield'
                                placeholder='Title'
                                name="title"
                                value={newpodcast.title}
                                onChange={handlePodcast}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}  >
                        <label className='form-labels label' for="my-input" >Image <Typography component='span' className='star'>*</Typography></label>
                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                            {statesingle.length < 1 &&

                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                </Button>
                            }
                        </Box>
                    </Grid>
                    {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                    <Grid item xs={12} sm={12} md={12}  >
                        {statesingle.length > 0 &&
                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                {statesingle.length > 0 ? statesingle.map((x, i) => (
                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                        <Box className='uploaded_img' >
                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                            <img src={x} alt='' height={155} width={195} />
                                        </Box>
                                    </Grid>
                                )) : ""}
                            </Grid>
                        }

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note:</strong> Image size should be more than 220px * 250px for better quality picture.</Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography component='div' className='label'>Audio/Video <Typography component='span' className='star'>*</Typography> </Typography>

                    </Grid>

                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Box className=" ">
                            <RadioGroup
                                row
                                aria-labelledby="demo-customized-radios"
                                name="payment_type"
                                onChange={handleEditGallery}
                                value={selectGallert}
                            >
                                <FormControlLabel value="Podcast" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Audio</Typography>} />
                                <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                            </RadioGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography component='div' className='label'>Upload Audio </Typography>

                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        {selectGallert === 'Podcast' ? <>
                            <Box className="mb-20">
                                <FormControl variant="standard" fullWidth>
                                    <Box className='flex-center flex-between flex-wrap w100 ' >
                                        <Button className="upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input name="audio" accept="audio/*" onChange={handleAudio} hidden type="file" />
                                        </Button>

                                    </Box>
                                </FormControl>
                                {error.audio && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.audio}</p>}
                            </Box>
                            {audioPreview !== null && <Box className='audioPlayer_container mb-20'>
                                <AudioPlayer
                                    // autoPlay
                                    src={audioPreview && audioPreview.slice(0, 4) === "blob" ? audioPreview : process.env.REACT_APP_BASE_URL + `${audioPreview}`}
                                    // onPlay={e => console.log("onPlay")}
                                    className='audio_player'
                                />
                            </Box>} </> : <>
                            <Box className="mb-20">
                                <Typography component='div' className='label'>Video</Typography>
                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {selectedVideo && selectedVideo?.length === 0 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                            </Box>
                                            <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                        </Button>
                                    }
                                    {selectedVideo && selectedVideo?.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className="uploaded_vdo" >
                                                    <Button className='close_icon' onClick={clearVideo}><Close /></Button>
                                                    <video className='uploaded_vdo' controls>
                                                        <source src={videoPreviewUrl} type={selectedVideo.type} />
                                                    </video>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                                {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                            </Box>
                        </>
                        }
                    </Grid>

                    {/* <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth>
                            <Typography component='div' className='label'>Upload Audio <Typography component='span' className='star'>*</Typography></Typography>
                            <input
                                type="file"
                                accept="audio/*"
                                name="audio"
                                //value={podcast.audio}
                                onChange={handleAudio}
                            />
                            {error.audio && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.audio}</p>}
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <label className='form-labels  label' for="my-input">Public</label>

                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Box className='heading-btns ' >
                            <FormControlLabel
                                className='m-0'
                                style={{ display: 'flex', alignItems: 'center' }}
                                control={<IOSSwitch checked={isToggled} onChange={handleToggle} />}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {/* <label className='form-labels mb-0' for="my-input">Select Users</label> */}
                        <FormControl fullWidth>
                            {/* <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className='select'
                                value={userName}
                                onChange={(e) => handleSelectUser(e,)}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    const selectedUser = user.filter(content => selected.includes(content.username));
                                    if (selectedUser.length === 0) {
                                        return "Any User";
                                    } else if (selectedUser.length === 1) {
                                        return selectedUser[0].username;
                                    } else {
                                        return `${selectedUser.length} Users selected`;
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {user && user.length > 0 ? user.map((name) => (
                                    <MenuItem key={name._id} value={name.username} >
                                        <Checkbox checked={userName.indexOf(name.username) > -1} />
                                        <ListItemText primary={name.username} />
                                    </MenuItem>
                                )) : <MenuItem>No user found</MenuItem>}
                            </Select> */}
                            {/* <p className='error-field'>{error.userselect}</p> */}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {/* <label className='form-labels mb-0' for="my-input">Select Community</label> */}
                        <FormControl fullWidth>
                            {/* <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className='select'
                                value={coummunityName}
                                onChange={(e) => handlechangeCommunity(e)}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    const selectedCommunity = community.filter(content => selected.includes(content.coummunity_name));
                                    if (selectedCommunity.length === 0) {
                                        return "Any Community";
                                    } else if (selectedCommunity.length === 1) {
                                        return selectedCommunity[0].coummunity_name;
                                    } else {
                                        return `${selectedCommunity.length} Community selected`;
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {community && community.length > 0 ? community.map((name) => (
                                    <MenuItem key={name._id} value={name.coummunity_name}>
                                        <Checkbox checked={coummunityName.indexOf(name.coummunity_name) > -1} />
                                        <ListItemText primary={name.coummunity_name} />
                                    </MenuItem>
                                )) : <MenuItem>No community found</MenuItem>}
                            </Select> */}

                            {/* <p className='error-field'>{error.userselect}</p> */}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            {
                               truebutton?
                               <Button variant="contained" className='theme-btn ' >Please wait...</Button>
                               :
                               <Button variant="contained" className='theme-btn ' onClick={handleSubmit}>Submit</Button>
                            }
                         
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}